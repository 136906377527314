import React from "react"

export class NameTitle extends React.Component {
  render() {
    return (
      <h3
        style={
          !this.props.isSubtitle
            ? styles.nameTitle
            : { ...styles.nameTitle, ...styles.nameSubtitle }
        }
      >
        {this.props.name}
      </h3>
    )
  }
}

const styles = {
  nameTitle: {
    marginBottom: 0,
    marginTop: 0,
  },
  nameSubtitle: {
    marginTop: 2,
    color: "#555",
    fontSize: "0.9rem",
  },
}
