import React from "react"
import ProfileImage from "./profileImage"
import { Link } from "gatsby"
import { animated } from "react-spring"
import { NameTitle } from "./nameTitle"

export class HeaderTitles extends React.Component {
  render() {
    return (
      <Link to="/">
        <animated.div
          className="navbarLeftContainer"
          style={{
            ...styles.navbarLeftContainer,
            ...this.props.spring,
          }}
        >
          <ProfileImage />
          <div>
            <NameTitle name="Liam" />
            <NameTitle name="McMains" />
            <NameTitle name="Software Engineer" isSubtitle />
          </div>
        </animated.div>
      </Link>
    )
  }
}

const styles = {
  navbarLeftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 20,
  },
}
