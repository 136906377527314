import PropTypes from "prop-types"
import React from "react"

import { useTrail, useSpring } from "react-spring"
import { HamburgerMenu } from "./HamburgerMenu"
import { HeaderLinks } from "./HeaderLinks"
import { HeaderTitles } from "./HeaderTitles"

const Header = props => {
  const hamburgerLinks = [
    {
      title: "WORK",
      to: "/",
    },
    {
      title: "ABOUT ME",
      to: "/about",
    },
    // {
    //   title: "BLOG",
    //   to: "/blog",
    // },
    {
      title: "CONTACT",
      to: "/contact",
    },
  ]

  const trail = useTrail(hamburgerLinks.length, {
    opacity: 1,
    config: { mass: 1, tension: 50, friction: 10 },
    from: { opacity: 0, color: "black" },
  })

  const spring = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { mass: 1, tension: 750, friction: 200 },
  })

  return (
    <header style={styles.navbar}>
      <div style={styles.navbarPaddedContainer}>
        <HeaderTitles spring={spring} />
        <HeaderLinks hamburgerLinks={hamburgerLinks} trails={trail} />

        <HamburgerMenu hamburgerLinks={hamburgerLinks} />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export const styles = {
  navbar: {
    marginBottom: `1.45rem`,
    display: "flex",
    justifyContent: "center",
  },
  navbarPaddedContainer: {
    maxWidth: 1300,
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
}

export default Header
