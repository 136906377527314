import React from "react"
import { Link } from "gatsby"
import { animated } from "react-spring"

export default function NavbarLink(props) {
  let borderedStyle = props.bordered ? styles.bordered : {}

  if (props.onClick) {
    return (
      // eslint-disable-next-line
      <a onClick={props.onClick}>
        <animated.div style={props.trail}>
          <h3
            className="navbarLink"
            style={{ ...styles.link, ...borderedStyle }}
          >
            {props.title}
          </h3>
        </animated.div>
      </a>
    )
  } else {
    if (props.external) {
      return (
        <a href={props.to}>
          <animated.div style={props.trail}>
            <h3
              className="navbarLink"
              style={{ ...styles.link, ...borderedStyle }}
            >
              {props.title}
            </h3>
          </animated.div>
        </a>
      )
    } else {
      return (
        <Link to={props.to}>
          <animated.div style={props.trail}>
            <h3
              className="navbarLink"
              style={{ ...styles.link, ...borderedStyle }}
            >
              {props.title}
            </h3>
          </animated.div>
        </Link>
      )
    }
  }
}

const styles = {
  link: {
    marginRight: 20,
  },
  bordered: {
    borderWidth: 2,
    padding: "12px 30px 10px 30px",
    borderRadius: 30,
    borderColor: "#000",
    borderStyle: "solid",
  },
}
