import React from "react"

export default function VerticallyCenteredRow(props) {
  return (
    <div
      style={{ ...styles.verticallyCenteredRow, ...props.style }}
      className={props.className}
    >
      {props.children}
    </div>
  )
}

const styles = {
  verticallyCenteredRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}
