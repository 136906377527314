import React from "react"
import { Link } from "gatsby"
import { stack as Menu } from "react-burger-menu"

export class HamburgerMenu extends React.Component {
  render() {
    return (
      <div className="navbarRightSmallContainer">
        <Menu pageWrapId="page-wrap" right>
          {this.props.hamburgerLinks.map((link, index) => {
            return (
              <Link key={index} to={link.to} className="menu-item">
                <h1
                  style={{
                    color: "white",
                    backgroundColor: "black",
                  }}
                >
                  {link.title}
                </h1>
              </Link>
            )
          })}
        </Menu>
      </div>
    )
  }
}
