import React from "react"
import NavbarLink from "./navbarLink"

export class HeaderLinks extends React.Component {
  render() {
    return (
      <div className="navbarRightContainer" style={styles.navbarRightContainer}>
        {this.props.hamburgerLinks.map((link, index) => {
          return (
            <NavbarLink
              onClick={onClick(index)}
              key={index}
              title={link.title}
              trail={this.props.trails[index]}
              to={link.to}
              bordered={index === this.props.hamburgerLinks.length - 1}
            />
          )
        })}
      </div>
    )
  }
}

const styles = {
  navbarRightContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}

function onClick(index) {
  return index === 0
    ? () => {
        scrollToWork()
      }
    : undefined
}

function scrollToWork() {
  const isOnRootPage = window.location.pathname === "/"
  if (isOnRootPage) {
    let workElement = document.getElementById("work1")
    workElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  } else {
    window.location = "/#"
    window.location = "/#work"
  }
}
