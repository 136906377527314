import React from "react"
import VerticallyCenteredRow from "./verticallyCenteredRow"
import { Link } from "gatsby"

export default function footer(props) {
  return (
    <div style={footerContainerStyle(props)}>
      <div style={styles.contentContainer}>
        {/* <VerticallyCenteredRow style={styles.leftContainer}>
          <p style={styles.linkText}>Left</p>
        </VerticallyCenteredRow> */}

        <VerticallyCenteredRow style={styles.rightContainer}>
          <Link to="/" style={styles.linkText} className="footerLink">
            WORK
          </Link>
          <Link to="/about" style={styles.linkText} className="footerLink">
            ABOUT ME
          </Link>
          <Link to="/blog" style={styles.linkText} className="footerLink">
            BLOG
          </Link>
          <Link to="/contact" style={styles.linkText} className="footerLink">
            CONTACT
          </Link>
          <Link
            to="/contact"
            style={{ ...styles.linkCircled, ...styles.linkText }}
            className="footerLink"
          >
            HIRE ME
          </Link>
        </VerticallyCenteredRow>
      </div>
    </div>
  )
}

let footerContainerStyle = ({ fixedFooter }) => {
  return {
    position: fixedFooter ? "fixed" : "static",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 60,
    backgroundColor: "#333",
  }
}

const styles = {
  contentContainer: {
    maxWidth: 1200,
    flexDirection: "row",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "0px auto",
  },
  leftContainer: {},
  rightContainer: {
    maxWidth: 600,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  linkText: {
    backgroundColor: "transparent",
    color: "#999",
  },
  linkCircled: {
    borderRadius: 30,
    padding: "0px 15px",
    borderWidth: 1,
    borderColor: "#999",
    borderStyle: "solid",
  },
}
